<template>
  <div class="flex justify-center">
    <Card class="mt-5 signup-card">
      <template #title>Registreeri</template>
      <template #content>
        <div class="fluid">
          <div class="field">
            <label for="username">Kasutajanimi</label>
            <InputText
                id="username"
                type="text"
                v-model.trim="v$.username.$model"
                :class="{ 'invalid': v$.username.$error }"
            />
            <small v-if="(v$.username.$error)" class="error">{{ v$.username.required.$message }}</small>
          </div>
          <div class="field">
            <label for="password">Parool</label>
            <InputText
                id="password"
                type="password"
                v-model.trim="v$.password.$model"
                :class="{ 'invalid': v$.password.$error }"
            />
            <small v-if="(v$.password.$error)" class="error">{{ v$.password.required.$message }}</small>
          </div>
          <div class="field">
            <label for="password">Parool veelkord</label>
            <InputText
                id="password-confirm"
                type="password"
                v-model.trim="v$.passwordConfirm.$model"
                :class="{ 'invalid': v$.passwordConfirm.$error }"
            />
            <small v-if="(v$.passwordConfirm.$error)" class="error">{{ v$.passwordConfirm.required.$message }}</small>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="text-right">
          <Button label="Registreeri" @click="signup" :disabled="v$.$invalid"/>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { reactive, inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';
import * as ApiService from '../api-service';
import { required } from '../validators';

export default {
  components: {
    Button,
    InputText,
    Card
  },

  setup() {
    const router = useRouter();

    const userInfo = inject('userInfo');
    const signupData = reactive({});

    const rules = {
      username: { required },
      password: { required },
      passwordConfirm: { required }
    };

    const v$ = useVuelidate(rules, signupData);

    let signupFailed = ref(false);

    const signup = async () => {
      try {
        await ApiService.signup(signupData);
      } catch (e) {
        signupFailed.value = true;
        return;
      }

      await router.replace({ name: 'Login' });
    };

    return { v$, signup, signupFailed };
  }
};
</script>

<style scoped>

</style>
