<template>
  <Button label="Loo pisipildid" @click="generateThumbnails"/>
</template>

<script setup>
import Button from 'primevue/button';
import * as ApiService from '../api-service';

async function generateThumbnails() {
  await ApiService.generateThumbnails();
}
</script>

<style scoped></style>
