export const etLocale = {
  startsWith: 'Algab',
  contains: 'Sisaldab',
  notContains: 'Ei sisalda',
  endsWith: 'Lõpeb',
  equals: 'Võrdub',
  notEquals: 'Ei võrdu',
  noFilter: 'Filtrit pole',
  lt: 'Vähem kui',
  lte: 'Vähem kui või võrdne',
  gt: 'Rohkem kui',
  gte: 'Rohkem kui või võrdne',
  dateIs: 'Kuupäev on',
  dateIsNot: 'Kuupäev ei ole',
  dateBefore: 'Kuupäev on enne',
  dateAfter: 'Kuupäev on pärast',
  clear: 'Puhasta',
  apply: 'Rakenda',
  matchAll: 'Sobita kõiki',
  matchAny: 'Sobita ükskõik milline',
  addRule: 'Lisa reegel',
  removeRule: 'Eemalda reegel',
  accept: 'Jah',
  reject: 'Ei',
  choose: 'Vali',
  upload: 'Laadi üles',
  cancel: 'Tühista',
  dayNames: [
    'Pühapäev',
    'Esmaspäev',
    'Teisipäev',
    'Kolmapäev',
    'Neljapäev',
    'Reede',
    'Laupäev',
  ],
  dayNamesShort: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
  dayNamesMin: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
  monthNames: [
    'Jaanuar',
    'Veebruar',
    'Märts',
    'Aprill',
    'Mai',
    'Juuni',
    'Juuli',
    'August',
    'September',
    'Oktoober',
    'November',
    'Detsember',
  ],
  monthNamesShort: [
    'Jaan',
    'Veebr',
    'Märts',
    'Apr',
    'Mai',
    'Juuni',
    'Juuli',
    'Aug',
    'Sept',
    'Okt',
    'Nov',
    'Dets',
  ],
  today: 'Täna',
  weekHeader: 'Ndl',
  firstDayOfWeek: 1,
  dateFormat: 'dd.mm.yy',
  weak: 'Nõrk',
  medium: 'Keskmine',
  strong: 'Tugev',
  passwordPrompt: 'Sisesta parool',
  emptyFilterMessage: 'Tulemusi ei leitud',
  emptyMessage: 'Valikud puuduvad',
  aria: {
    trueLabel: 'True',
    falseLabel: 'False',
    nullLabel: 'Not Selected',
    star: '1 star',
    stars: '{star} stars',
    selectAll: 'All items selected',
    unselectAll: 'All items unselected',
    close: 'Close',
    previous: 'Previous',
    next: 'Next',
    navigation: 'Navigation',
    scrollTop: 'Scroll Top',
    moveTop: 'Move Top',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    moveBottom: 'Move Bottom',
    moveToTarget: 'Move to Target',
    moveToSource: 'Move to Source',
    moveAllToTarget: 'Move All to Target',
    moveAllToSource: 'Move All to Source',
    pageLabel: '{page}',
    firstPageLabel: 'First Page',
    lastPageLabel: 'Last Page',
    nextPageLabel: 'Next Page',
    previousPageLabel: 'Previous Page',
    rowsPerPageLabel: 'Rows per page',
    jumpToPageDropdownLabel: 'Jump to Page Dropdown',
    jumpToPageInputLabel: 'Jump to Page Input',
    selectRow: 'Row Selected',
    unselectRow: 'Row Unselected',
    expandRow: 'Row Expanded',
    collapseRow: 'Row Collapsed',
    showFilterMenu: 'Show Filter Menu',
    hideFilterMenu: 'Hide Filter Menu',
    filterOperator: 'Filter Operator',
    filterConstraint: 'Filter Constraint',
    editRow: 'Row Edit',
    saveEdit: 'Save Edit',
    cancelEdit: 'Cancel Edit',
    listView: 'List View',
    gridView: 'Grid View',
    slide: 'Slide',
    slideNumber: '{slideNumber}',
    zoomImage: 'Zoom Image',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    rotateRight: 'Rotate Right',
    rotateLeft: 'Rotate Left',
  }
};