<template>
  <Menubar :model="menuItems" v-if="!['/login', '/signup'].includes(route.path)">
    <template #start>
      <router-link to="/">
        <img alt="logo" src="android-chrome-192x192.png" height="40" class="mr-2" />
      </router-link>
    </template>
    <template #end>
      <div class="flex align-items-center">
        <span class="pi pi-user mr-2"></span>
        <span class="mr-3">{{ userInfo.userInfo.username }}</span>
        <Button text label="Logi välja" icon="pi pi-power-off" @click="logout"/>
      </div>
    </template>
  </Menubar>
  <div class="app-content">
    <router-view/>
  </div>
  <Toast/>
  <ConfirmDialog/>
</template>

<script setup>
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@/assets/css/main.css';
import { onMounted, provide, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import userInfo from './store/user-info';
import axios from 'axios';
import * as ApiService from './api-service';
import Menubar from 'primevue/menubar';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import ConfirmDialog from 'primevue/confirmdialog';

provide('userInfo', userInfo);

const router = useRouter();
const route = useRoute();
const toast = useToast();

const menuItems = ref([
  {
    label: 'Asjad',
    to: '/'
  },
  {
    label: 'Kategooriad',
    to: '/items/categories'
  },
  {
    label: 'Tööriistad',
    to: '/tools'
  }
]);

if (localStorage.getItem('access_token')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('access_token') }`;
}

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response;
}, async function (error) {
  if (error.response.status === 401) {
    // Try to refresh access token if refresh token is available
    if (localStorage.getItem('refresh_token') !== null) {
      try {
        const refreshTokenResponse = await ApiService.refreshTokens();
        localStorage.setItem('access_token', refreshTokenResponse.data.access_token);
        localStorage.setItem('refresh_token', refreshTokenResponse.data.refresh_token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${ refreshTokenResponse.data.access_token }`;
        error.config.headers['Authorization'] = `Bearer ${ refreshTokenResponse.data.access_token }`;

        return axios.request(error.config);
      } catch (e) {
        await router.replace({ name: 'Login' });
      }
    }
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (error.response.status === 500 && error.response.data.message) {
    toast.add({
          severity: 'error',
          summary: 'Viga',
          detail: error.response.data.message,
          life: 5000
        }
    );
  }

  return Promise.reject(error);
});

onMounted(async () => {
  const response = await ApiService.getCurrentUser();
  userInfo.setUserInfo(response.data);
});

const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  toast.add({
        severity: 'success',
        summary: 'Välja logimine õnnestus',
        detail: 'Olete edukalt välja logitud',
        life: 5000
      }
  );
  router.push({ name: 'Login' });
};
</script>

<style lang="scss">
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.app-content {
  margin: 1rem;
}

a {
  text-decoration: none;
  color: inherit;
}


.p-message .p-message-icon {
  margin-right: 0.8rem !important;
}

.p-galleria .p-galleria-close {
  z-index: 1005;
}

@media screen and (max-width: 768px) {
  .p-toast {
    max-width: 90vw;
  }
}
</style>
