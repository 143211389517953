<template>
  <div>
    <Panel header="Lisa triipkoodi järgi" class="mb-5" toggleable :collapsed="true">
      <div class="field">
        <label for="barcode">Triipkood</label>
        <div class="flex">
          <InputText id="barcode" type="text" v-model="editedItem.metadata.barcode" class="w-full" />
          <Button @click="searchBarcode" icon="pi pi-search" aria-label="Submit" class="ml-2" />
        </div>
        <small v-if="eanItemNotFound" class="text-red-500">Sellise triipkoodiga toodet ei leitud</small>
        <div v-if="loadingEanItem" class="text-center mt-2">
          <ProgressSpinner style="width: 50px; height: 50px"/>
        </div>
      </div>
      <div class="flex justify-content-center">
        <div class="barcode-image-container">
          <img class="barcode-image" v-if="eanItemData"
            :src="`${attachmentsBaseUrl}/${eanItemData.thumbnailRelativePath}`" :alt="eanItemData.name" />
        </div>
      </div>
    </Panel>
    <div class="field">
      <label for="name">Nimetus*</label>
      <InputText id="name" type="text" v-model="editedItem.name" :class="{ 'invalid': v$.name.$error }" class="w-full" />
      <small v-if="v$.name.$error" class="error">{{ v$.name.$errors[0].$message }}</small>
    </div>
    <div class="field">
      <label for="description">Kirjeldus</label>
      <Editor id="description" v-model="editedItem.description" editorStyle="height: 200px" />
    </div>
    <div class="field">
      <label for="category">Kategooria*</label>
      <Dropdown id="category" :options="categories" v-model="editedItem.category" optionLabel="name"
        :class="{ 'invalid': v$.category.$error }" class="w-full" />
      <small v-if="v$.category.$error" class="error">{{ v$.category.$errors[0].$message }}</small>
    </div>
    <div class="field">
      <label for="expiryDate">Aegumiskuupäev</label>
      <Calendar v-model="expiryDate" id="expiryDate" class="w-full" show-icon/>
    </div>
    <div class="field">
      <label for="amount">Kogus</label>
      <InputNumber v-model="editedItem.metadata.amount" id="amount" class="w-full"/>
    </div>
    <div class="field">
      <label for="location">Asukoht</label>
      <InputText id="location" type="text" v-model="editedItem.metadata.location" class="w-full" />
    </div>
    <div class="field">
      <label for="boxNumber">Kasti number</label>
      <InputText id="boxNumber" type="text" aria-describedby="boxNumber-help" v-model="editedItem.metadata.boxNumber"
        class="w-full" />
      <small id="boxNumber-help">Järjekorras järgmine vaba kastinumber on {{ nextFreeBoxNumber }}. Kasutage seda kui muud
        vajadust pole.</small>
    </div>
    <div class="field">
      <label for="attachments">Failid / pildid</label>
      <div class="mb-3" v-for="attachment in editedItemAttachments" :key="attachment.id">
        <div class="flex justify-between">
          <img v-if="utils.isImageFile(attachment.fileName)" class="image-thumbnail"
            :src="`${attachmentsBaseUrl}/${attachment.relativeFilePath}`" alt="image">
          <a v-else :href="`${attachmentsBaseUrl}/${attachment.relativeFilePath}`">{{ attachment.fileName }}</a>
          <Button severity="danger" text icon="pi pi-trash" @click="deleteAttachment(attachment.id)" />
        </div>
        <div v-if="utils.isImageFile(attachment.fileName)" class="image-filename">{{ attachment.fileName }}</div>
      </div>
      <FileUpload ref="fileUpload" name="files" :url="editedItemAttachmentsUrl" id="attachments" :multiple="true"
        @beforeSend="beforeAttachmentSend" @upload="uploadComplete" :showUploadButton="false" :showCancelButton="false">
        <template #empty>
          <p>Lohistage üleslaaditavad failid siia.</p>
        </template>
      </FileUpload>
    </div>
  </div>
  <div class="flex justify-end">
    <Button class="mr-2" severity="secondary" outlined label="Tagasi" @click="stopEditing" />
    <Button label="Salvesta" v-if="modeIsCreate" @click="createItem" :disabled="v$.$invalid || uploadingFiles" />
    <Button label="Salvesta" v-if="modeIsEdit" @click="updateItem" :disabled="v$.$invalid || uploadingFiles" />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed, nextTick, toRefs, watch } from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Panel from 'primevue/panel';
import Calendar from 'primevue/calendar';
import * as ApiService from '../api-service';
import * as utils from '@/utils.js';
import { required } from '@/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import { useRoute, useRouter } from 'vue-router';
import Editor from 'primevue/editor';
import { formatDate } from '@/filters';
import InputNumber from 'primevue/inputnumber';
import ProgressSpinner from 'primevue/progressspinner';


const props = defineProps({
  mode: {
    type: String,
    required: true
  }
});

const categories = ref([]);
const editedItem = reactive({
  metadata: {}
});
const { mode } = toRefs(props);
const fileUpload = ref(null);
const editedItemAttachmentsUrl = ref(null);
const editedItemAttachments = ref([]);
const createdItemId = ref(null);
const nextFreeBoxNumber = ref(null);
const attachmentsBaseUrl = process.env.https://asjad.priki.ee/attachments;
const eanItemNotFound = ref(false);
const eanItemData = ref(null);
const expiryDate = ref(null);
const loadingEanItem = ref(false);
const uploadingFiles = ref(false);

const toast = useToast();
const rules = computed(() => {
  return {
    name: { required, $autoDirty: true },
    category: { required, $autoDirty: true }
  };
});

const route = useRoute();
const router = useRouter();

const v$ = useVuelidate(rules, editedItem);

onMounted(async () => {
  if (modeIsEdit.value) {
    await loadInventoryItem(route.params.id);
    await loadInventoryItemAttachments(route.params.id);
  }

  await loadNextFreeBoxNumber();
  const response = await ApiService.getAllInventoryCategories();
  categories.value = response.data;

  if (route.query.eanItemId) {
    await loadEanItem(route.query.eanItemId);
  }
});

watch(eanItemData, (newValue) => {
  if (newValue === null) {
    return;
  }

  eanItemNotFound.value = false;
  editedItem.name = eanItemData.value.name;
  editedItem.metadata.barcode = eanItemData.value.code;
  editedItem.metadata.eanItemId = eanItemData.value.id;
});

watch(expiryDate, () => {
  editedItem.metadata.expiryDate = formatDate(expiryDate.value, 'YYYY-MM-DD');
});

const modeIsEdit = computed(() => {
  return mode.value === 'EDIT';
});

const modeIsCreate = computed(() => {
  return mode.value === 'CREATE';
});

const loadInventoryItem = async (itemId) => {
  const response = await ApiService.getInventoryItem(itemId);
  Object.assign(editedItem, response.data);
  if (editedItem.metadata.expiryDate) {
    expiryDate.value = new Date(editedItem.metadata.expiryDate);
  }
};

const loadInventoryItemAttachments = async (itemId) => {
  const response = await ApiService.getInventoryItemAttachments(itemId);
  editedItemAttachments.value = response.data;
};

const loadEanItem = async (eanItemId) => {
  const response = await ApiService.getEanItem(eanItemId);
  eanItemData.value = response.data;
};

const createItem = async () => {
  const response = await ApiService.createInventoryItem(editedItem);
  editedItemAttachmentsUrl.value = `${process.env.https://asjad.priki.ee/api}/inventory/items/${response.data.id}/attachments`;
  createdItemId.value = response.data.id;
  await nextTick();
  if (fileUpload.value.files.length > 0) {
    toast.add({
      severity: 'info',
      summary: 'Failide üleslaadimine',
      detail: 'Failide üleslaadimine algas',
      life: 5000
    });
    uploadingFiles.value = true;
    fileUpload.value.upload();
  } else {
    toast.add({
      severity: 'success',
      summary: 'Õnnestus',
      detail: 'Uus asi lisatud',
      life: 5000
    }
    );
    await router.push({ name: 'Item', params: { id: response.data.id } });
  }
};

const updateItem = async () => {
  const response = await ApiService.updateInventoryItem(editedItem);
  editedItemAttachmentsUrl.value = `${process.env.https://asjad.priki.ee/api}/inventory/items/${response.data.id}/attachments`;
  await nextTick();
  if (fileUpload.value.files.length > 0) {
    toast.add({
      severity: 'info',
      summary: 'Failide üleslaadimine',
      detail: 'Failide üleslaadimine algas',
      life: 5000
    });
    uploadingFiles.value = true;
    fileUpload.value.upload();
  } else {
    toast.add({
      severity: 'success',
      summary: 'Õnnestus',
      detail: 'Muudatused salvestatud',
      life: 5000
    });
    stopEditing();
  }
};

const beforeAttachmentSend = (event) => {
  event.xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
};

const uploadComplete = async (event) => {
  uploadingFiles.value = false;

  if (modeIsEdit.value) {
    await loadInventoryItemAttachments(route.params.id);
  }

  const responses = JSON.parse(event.xhr.response);
  const failedUploads = [];
  responses.forEach(response => {
    if (response.status === 'FAILURE') {
      failedUploads.push(response);
    }
  });

  const toastDetailMessage = modeIsEdit.value ? 'Muudatused salvestatud' : 'Uus asi lisatud';
  toast.add({
    severity: 'success',
    summary: 'Õnnestus',
    detail: toastDetailMessage,
    life: 5000
  }
  );

  if (failedUploads.length > 0) {
    toast.add({
      severity: 'error',
      summary: 'Viga',
      detail: 'Osade failide üleslaadimine ebaõnnestus',
      life: 5000
    }
    );
  }

  if (modeIsEdit.value) {
    await router.push({ name: 'Item', params: { id: route.params.id } });
  }

  if (modeIsCreate.value) {
    await router.push({ name: 'Item', params: { id: createdItemId.value } });
  }
};

const deleteAttachment = async (attachmentId) => {
  await ApiService.deleteAttachmentFile(attachmentId);
  editedItemAttachments.value = editedItemAttachments.value
    .filter(attachment => attachment.id !== attachmentId);
};

const stopEditing = () => {
  if (modeIsEdit.value) {
    router.push({ name: 'Item', params: { id: route.params.id } });
  }

  if (modeIsCreate.value) {
    router.push({ name: 'Items' });
  }
};

const loadNextFreeBoxNumber = async () => {
  const response = await ApiService.getNextFreeBoxNumber();
  nextFreeBoxNumber.value = response.data;
}

const searchBarcode = async () => {
  if (!editedItem.metadata.barcode) {
    return;
  }

  eanItemData.value = null;
  loadingEanItem.value = true;
  try {
    const result = await ApiService.searchEanItem(editedItem.metadata.barcode);
    if (result.data.name) {
      eanItemData.value = result.data;
    }
  } catch (error) {
    console.log(error);
    eanItemNotFound.value = true;
  } finally {
    loadingEanItem.value = false;
  }
}
</script>

<style scoped>
.image-thumbnail {
  width: 120px;
}

.image-filename {
  font-size: 0.7em;
}

.barcode-image-container {
  max-width: 200px;
  max-height: 200px;
}

.barcode-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>
