<template>
  <Dialog :header="headerText" v-model:visible="dialogVisible" @hide="closeDialog"
          :breakpoints="{'992px': '75vw', '768px': '100vw'}" :style="{width: '50vw'}"
          dismissableMask modal
  >
    <div>
      <div class="field">
        <label for="name">Nimetus*</label>
        <InputText id="name" type="text" v-model="editedCategory.name" :class="{ 'invalid': v$.name.$error }" class="w-full"/>
        <small v-if="v$.name.$error" class="error">{{ v$.name.$errors[0].$message }}</small>
      </div>
      <div class="flex justify-end">
        <Button class="mr-2" severity="secondary" outlined label="Sulge" @click="closeDialog"/>
        <Button v-if="modeIsCreate" label="Salvesta" :disabled="v$.$invalid" @click="createCategory"/>
        <Button v-if="modeIsEdit" label="Salvesta" :disabled="v$.$invalid" @click="updateCategory"/>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { computed, reactive, ref, toRefs, watch } from 'vue';
import { required } from '@/validators';
import { useVuelidate } from '@vuelidate/core';
import * as ApiService from '../api-service';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';

export default {
  components: {
    Dialog,
    InputText,
    Button
  },

  props: {
    visible: {
      type: Boolean,
      required: true
    },

    category: {
      type: Object,
      default: undefined
    }
  },

  setup(props, { emit }) {
    const { visible } = toRefs(props);
    const dialogVisible = ref(props.visible);
    const editedCategory = reactive({});

    const rules = computed(() => {
      return {
        name: { required, $autoDirty: true }
      };
    });

    const modeIsEdit = computed(() => {
      return !!props.category;
    });

    const modeIsCreate = computed(() => {
      return !props.category;
    });

    const headerText = computed(() => {
      if (modeIsEdit.value) return 'Kategooria muutmine';

      return 'Uue kategooria lisamine';
    })

    const toast = useToast();
    const v$ = useVuelidate(rules, editedCategory);

    watch(visible, async (newValue) => {
      dialogVisible.value = newValue;

      if (props.category) {
        Object.assign(editedCategory, props.category);
      }
    });

    const closeDialog = () => {
      Object.assign(editedCategory, {
        name: undefined
      });
      v$.value.$reset();
      emit('close');
    };

    const createCategory = async () => {
      const response = await ApiService.createInventoryCategory(editedCategory);
      toast.add({
            severity: 'success',
            summary: 'Õnnestus',
            detail: 'Uus kategooria loodud',
            life: 5000
          }
      );
      closeDialog();
      emit('create:category', response.data);
    };

    const updateCategory = async () => {
      const response = await ApiService.updateInventoryCategory(editedCategory);
      toast.add({
            severity: 'success',
            summary: 'Õnnestus',
            detail: 'Muudatused salvestatud',
            life: 5000
          }
      );
      closeDialog();
      emit('update:category', response.data);
    };

    return {
      editedCategory,
      closeDialog,
      dialogVisible,
      v$,
      modeIsCreate,
      modeIsEdit,
      headerText,
      createCategory,
      updateCategory
    };
  }
};
</script>

<style scoped></style>