import { createRouter, createWebHashHistory } from 'vue-router';
import Login from '@/views/Login';
import Signup from '@/views/Signup';
import userInfo from '../store/user-info';
import Items from '@/views/Items';
import Item from '@/views/Item';
import ItemEdit from '@/views/ItemEdit';
import ItemCreate from '@/views/ItemCreate';
import ItemCategories from '@/views/ItemCategories';
import Tools from '@/views/Tools';

const routes = [
  // -------
  // General
  // -------
  {
    path: '/',
    name: 'Home',
    component: Items,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },

  // -----
  // Items
  // -----
  {
    path: '/items',
    name: 'Items',
    component: Items,
    meta: { requiresAuth: true }
  },
  {
    path: '/items/create',
    name: 'ItemCreate',
    component: ItemCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/items/:id',
    name: 'Item',
    component: Item,
    meta: { requiresAuth: true }
  },
  {
    path: '/items/:id/edit',
    name: 'ItemEdit',
    component: ItemEdit,
    meta: { requiresAuth: true }
  },

  // ---------------
  // Item categories
  // ---------------
  {
    path: '/items/categories',
    name: 'ItemCategories',
    component: ItemCategories,
    meta: { requiresAuth: true }
  },

  // ---------------
  // Tools
  // ---------------
  {
    path: '/tools',
    name: 'Tools',
    component: Tools,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!userInfo.userInfo.loggedIn) {
      next({ name: 'Login' , query: { redirect: to.fullPath }});
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
