<template>
  <Panel>
    <div class="">
      <label for="search" class="block text-xs font-medium uppercase">Otsi</label>
      <AutoComplete v-model="quickSearchItem" :suggestions="filteredItems" @complete="quickSearch($event)"
                    @item-select="selectItem" field="name" class="mr-2" @keypress.enter="search" id="search">
        <template #option="slotProps">
          <div class="flex align-options-center">
            <div>
              <span>{{ slotProps.option.name }}</span>
              <span v-if="slotProps.option.metadata.boxNumber" class="text-sm"> (Kast nr: {{ slotProps.option.metadata.boxNumber }})</span>
            </div>
          </div>
        </template>
      </AutoComplete>
      <Button label="Otsi" icon="pi pi-search" @click="search" class="mr-2"/>
      <Button v-if="quickSearchItem" icon="pi pi-times" @click="clearFilters" severity="secondary"/>
    </div>
    <div class="mt-3 flex justify-content-between flex-wrap">
      <div class="mt-2">
        <label for="sortOrder" class="block text-xs font-medium uppercase">Sorteerimine</label>
        <Dropdown v-model="sortOrder" :options="sortOptions" optionLabel="name" optionValue="code" placeholder="Sorteeri" id="sortOrder"/>
      </div>
      <div class="mt-2">
        <label for="category" class="block text-xs font-medium uppercase">Kategooria</label>
        <Dropdown v-model="filters.categoryId" :options="categoryOptions" optionLabel="name" optionValue="id" placeholder="Kategooria" id="category"/>
      </div>
    </div>
  </Panel>
</template>

<script setup>
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import Dropdown from 'primevue/dropdown';
import { onMounted, ref, watch } from 'vue';
import * as ApiService from '@/api-service';
import { useRoute, useRouter } from 'vue-router';

const quickSearchItem = ref();
const filteredItems = ref([]);
const categoryOptions = ref([]);
const filters = ref({});

const sortOrder = ref('createdAt,desc');
const sortOptions = [
  { name: 'Nimetuse järgi A-Z', code: 'name,asc' },
  { name: 'Nimetuse järgi Z-A', code: 'name,desc' },
  { name: 'Varem lisatud enne', code: 'createdAt,asc' },
  { name: 'Hiljem lisatud enne', code: 'createdAt,desc' },
  { name: 'Varem aeguvad enne', code: 'metadata.expiryDate,asc' },
  { name: 'Hiljem aeguvad enne', code: 'metadata.expiryDate,desc' }
];

const router = useRouter();
const route = useRoute();

onMounted(async () => {
  await loadCategoryOptions();
  if (route.query.query) {
    quickSearchItem.value = route.query.query;
  }
});

watch(() => route.query, () => {
  if (route.query.query) {
    quickSearchItem.value = route.query.query;
  } else {
    quickSearchItem.value = null;
  }
});

watch(sortOrder, () => {
  const queryParams = Object.assign({}, route.query);
  queryParams.sort = sortOrder.value;
  router.push({ name: 'Items', query: queryParams });
});

watch(filters.value, () => {
  const queryParams = Object.assign({}, route.query);
  for (const key in filters.value) {
    queryParams[key] = filters.value[key];
  }
  router.push({ name: 'Items', query: queryParams });
});

const loadCategoryOptions = async () => {
  const response = await ApiService.getAllInventoryCategories();
  categoryOptions.value = response.data;
};

const quickSearch = async (event) => {
  const response = await ApiService.quickSearch(event.query);
  filteredItems.value = response.data;
};

const selectItem = (event) => {
  router.push({ name: 'Item', params: { id: event.value.id } });
};

const search = () => {
  const queryParams = Object.assign({}, route.query);
  queryParams.query = quickSearchItem.value;
  router.push({ name: 'Items', query: queryParams });
};

const clearFilters = () => {
  router.push({ name: 'Items', query: {} });
}
</script>
