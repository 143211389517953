import dayjs from 'dayjs';

export function dateWithTime(date) {
  return dayjs(date).format('DD.MM.YYYY HH:mm');
}

export function formattedDate(date) {
  return dayjs(date).format('DD.MM.YYYY');
}

export function formatDate(date, format) {
  return dayjs(date).format(format);
}