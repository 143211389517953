<template>
  <div class="grid">
    <div class="col">
      <div class="item-container shadow-1 p-3">
        <div class="flex align-items-center justify-content-between item-title">
          {{ item.name }}
          <span>
            <Button text icon="pi pi-pencil" @click="editItem"/>
            <Button severity="danger" text icon="pi pi-trash" @click="deleteItem"/>
          </span>
        </div>
        <div class="mt-3">
          <div class="grid">
            <div class="col-12 md:col-3 mt-2">
              <img v-if="imageAttachments.length > 0" class="item-thumbnail" :src="`${attachmentsBaseUrl}/${imageAttachments[0].relativeFilePath}`" alt="thumbnail" @click="imageClick(0)">
              <div class="grid">
                <div v-for="(attachment, index) in imageAttachments" class="col-4" :key="attachment.id">
                  <img class="item-thumbnail" :src="`${attachmentsBaseUrl}/${attachment.relativeFilePath}`"
                       alt="item-image" @click="imageClick(index)">
                </div>
              </div>
              <div>
                <div v-for="attachment in otherAttachments" :key="attachment.id">
                  <a :href="`${attachmentsBaseUrl}/${attachment.relativeFilePath}`">
                    {{ attachment.fileName }}
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-9 mt-2">
              <div class="grid">
                <div class="col-12 md:col-3">
                  <span class="item-metadata-field-title">
                    <span class="pi pi-book mr-1" title="Kategooria"></span>Kategooria
                  </span>
                  <br>
                  <span>{{ item.category?.name }}</span>
                </div>
                <div class="col-12 md:col-3" v-if="item.metadata?.boxNumber">
                  <span class="item-metadata-field-title">
                    <span class="pi pi-folder mr-1" title="Kasti number"></span>Kasti number
                  </span>
                  <br>
                  <span>{{ item.metadata?.boxNumber }}</span>
                </div>
                <div class="col-12 md:col-3" v-if="item.metadata?.location">
                  <span class="item-metadata-field-title">
                    <span class="pi pi-compass mr-1" title="Asukoht"></span>Asukoht
                  </span>
                  <br>
                  <span>{{ item.metadata?.location }}</span>
                </div>
                <div class="col-12 md:col-3" v-if="item.metadata?.expiryDate">
                  <span class="item-metadata-field-title">
                    <span class="pi pi-calendar mr-1" title="Aegumiskuupäev"></span>Aegumiskuupäev
                  </span>
                  <br>
                  <span>{{ formattedDate(item.metadata?.expiryDate) }}</span>
                </div>
                <div class="col-12 md:col-3" v-if="item.metadata?.amount">
                  <span class="item-metadata-field-title">
                    <span class="pi pi-database mr-1" title="Kogus"></span>Kogus
                  </span>
                  <br>
                  <span>{{ item.metadata?.amount }}</span>
                </div>
              </div>
              <Divider/>
              <div v-html="item.description"></div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <span class="modified-info-text">
            Viimati muudetud {{ dateWithTime(item.modifiedAt) }} ({{ item.modifiedBy }})
          </span>
        </div>
      </div>
    </div>
  </div>
  <div>
  </div>

  <Galleria :value="imageAttachments" v-model:activeIndex="activeIndex" v-model:visible="displayCustom" :circular="true"
             :showItemNavigators="true" :showThumbnails="false" :fullScreen="true">
    <template #item="slotProps">
      <img class="galleria-image" :src="`${attachmentsBaseUrl}/${slotProps.item.relativeFilePath}`" alt="image"/>
    </template>
  </Galleria>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import * as ApiService from '../api-service';
import * as utils from '@/utils';
import { dateWithTime, formattedDate } from '@/filters';
import Divider from 'primevue/divider';
import Button from 'primevue/button';
import Galleria from 'primevue/galleria';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';

const route = useRoute();
const router = useRouter();
const confirm = useConfirm();
const toast = useToast();
const item = ref({});
const attachments = ref([]);
const activeIndex = ref(0);
const displayCustom = ref(false);
const attachmentsBaseUrl = process.env.https://asjad.priki.ee/attachments;
onMounted(async () => {
  await loadItem();
  await loadItemAttachments();
});

const thumbnailImageUrl = computed(() => {
  if (attachments.value.length > 0) {
    const firstImageAttachment = attachments.value.find(attachment => utils.isImageFile(attachment.fileName));
    if (firstImageAttachment) {
      return `${process.env.https://asjad.priki.ee/attachments}/${firstImageAttachment.relativeFilePath}`;
    }
  }

  return null;
});

const imageAttachments = computed(() => {
  return attachments.value.filter(attachment => utils.isImageFile(attachment.fileName) && attachment.relativeFilePath !== item.value.thumbnailRelativePath);
});

const otherAttachments = computed(() => {
  return attachments.value.filter(attachment => !utils.isImageFile(attachment.fileName));
});

const loadItem = async () => {
  const response = await ApiService.getInventoryItem(route.params.id);
  item.value = response.data;
};

const loadItemAttachments = async () => {
  const response = await ApiService.getInventoryItemAttachments(route.params.id);
  attachments.value = response.data;
};

const imageClick = (index) => {
  activeIndex.value = index;
  displayCustom.value = true;
};

const editItem = () => {
  router.push({ name: 'ItemEdit', params: { id: route.params.id }});
}

const deleteItem = () => {
  confirm.require({
    message: 'Kas olete kindel, et soovite valitud asja kustutada?',
    header: 'Asja kustutamine',
    icon: 'pi pi-exclamation-triangle',
    accept: async () => {
      await ApiService.deleteInventoryItem(route.params.id);
      toast.add({
          severity: 'success',
          summary: 'Õnnestus',
          detail: 'Asi kustutatud',
          life: 5000
        }
      );
      await router.push({ name: 'Items' });
    }
  });
};
</script>

<style scoped>
.item-container {
  border-radius: 3px;
}

.item-title {
  font-size: 1.8rem;
}

.item-thumbnail-wrapper {
  width: 120px;
}

.item-thumbnail {
  width: 100%;
  cursor: pointer;
}

.modified-info-text {
  font-size: 0.7rem;
}

.galleria-image {
  width: 100%;
  max-height: 100vh;
  object-fit: contain;
}

.item-metadata-field-title, .item-metadata-field-title .pi {
  font-size: 0.7rem;
}
</style>
