<template>
  <Toolbar class="mb-2">
    <template #end>
      <Button label="Lisa uus" icon="pi pi-plus" @click="openCategoryDialog"/>
    </template>
  </Toolbar>

  <DataTable :value="categories" responsiveLayout="scroll">
    <Column field="name" header="Nimi"></Column>
    <Column bodyClass="flex justify-content-end">
      <template #body="slotProps">
        <Button class="mr-2" text icon="pi pi-pencil" @click="editCategory(slotProps.data)"/>
        <Button severity="danger" text icon="pi pi-trash" @click="deleteCategory(slotProps.data.id)"/>
      </template>
    </Column>
  </DataTable>

  <ItemCategoryDialog
      :category="editedCategory"
      :visible="itemCategoryDialogVisible"
      @close="closeItemCategoryDialog"
      @create:category="createCategory"
      @update:category="updateCategory"
  />
</template>

<script>
import { onMounted, ref } from 'vue';
import * as ApiService from '../api-service';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import ItemCategoryDialog from '@/components/ItemCategoryDialog';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

export default {
  components: {
    ItemCategoryDialog,
    DataTable,
    Column,
    Button,
    Toolbar
  },

  setup() {
    const categories = ref([]);
    const editedCategory = ref(undefined);
    const itemCategoryDialogVisible = ref(false);

    const toast = useToast();
    const confirm = useConfirm();

    onMounted(() => {
      loadCategories();
    });

    const loadCategories = async () => {
      const response = await ApiService.getAllInventoryCategories();
      categories.value = response.data;
    };

    const openCategoryDialog = () => {
      itemCategoryDialogVisible.value = true;
    };

    const editCategory = (category) => {
      editedCategory.value = category;
      openCategoryDialog();
    };

    const closeItemCategoryDialog = () => {
      itemCategoryDialogVisible.value = false;
      editedCategory.value = undefined;
    };

    const createCategory = (category) => {
      categories.value.push(category);
    };

    const updateCategory = (category) => {
      const index = categories.value.findIndex(c => c.id === category.id);
      categories.value[index] = category;
    };

    const deleteCategory = async (categoryId) => {
      confirm.require({
        message: 'Kas olete kindel, et soovite valitud kategooria kustutada?',
        header: 'Kategooria kustutamine',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await ApiService.deleteInventoryCategory(categoryId);
          categories.value = categories.value.filter(c => c.id !== categoryId);
          toast.add({
                severity: 'success',
                summary: 'Õnnestus',
                detail: 'Kategooria kustutatud',
                life: 5000
              }
          );
        }
      });
    };

    return {
      categories,
      openCategoryDialog,
      itemCategoryDialogVisible,
      editedCategory,
      editCategory,
      closeItemCategoryDialog,
      createCategory,
      updateCategory,
      deleteCategory
    };
  }
}
</script>

<style scoped></style>