<template>
  <ItemDetailEditor mode="CREATE"/>
</template>

<script>

import ItemDetailEditor from '@/components/ItemDetailEditor';

export default {
  components: {
    ItemDetailEditor
  }
}
</script>

<style scoped></style>