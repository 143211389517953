<template>
  <div class="flex justify-content-center">
    <Card class="mt-5 login-card">
      <template #title> Logi sisse</template>
      <template #content>
        <Message class="mb-2" v-if="loginFailed" severity="error" :closable="false">
          Sisselogimine ebaõnnestus, palun proovige uuesti.
        </Message>
        <div>
          <div class="field">
            <label for="username">Kasutajanimi</label>
            <InputText
                id="username"
                type="text"
                v-model.trim="v$.username.$model"
                :class="{ 'invalid': v$.username.$error }"
                class="w-full"
            />
            <small v-if="(v$.username.$error)" class="error">{{ v$.username.required.$message }}</small>
          </div>
          <div class="field">
            <label for="password">Parool</label>
            <InputText
                id="password"
                type="password"
                v-model.trim="v$.password.$model"
                :class="{ 'invalid': v$.password.$error }"
                class="w-full"
                @keypress.enter="login"
            />
            <small v-if="(v$.password.$error)" class="error">{{ v$.password.required.$message }}</small>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="text-right">
          <Button label="Logi sisse" @click="login" :disabled="v$.$invalid"/>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import axios from 'axios';
import { reactive, inject, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';
import Message from 'primevue/message';
import * as ApiService from '../api-service';
import { required } from '../validators';

export default {
  components: {
    Button,
    InputText,
    Card,
    Message
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const userInfo = inject('userInfo');
    const loginData = reactive({});

    const rules = {
      username: { required },
      password: { required }
    };

    const v$ = useVuelidate(rules, loginData);

    let loginFailed = ref(false);

    const login = async () => {
      try {
        const response = await ApiService.login(loginData);
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${ response.data.access_token }`;
      } catch (e) {
        loginFailed.value = true;
        return;
      }

      const response = await ApiService.getCurrentUser();
      userInfo.setUserInfo(response.data);

      if (route.query.redirect) {
        await router.replace(route.query.redirect);
      } else {
        await router.replace({ name: 'Home' });
      }
    };

    return { v$, login, loginFailed };
  }
};
</script>

<style scoped>
.login-card {
  width: 400px;
}
</style>
