export function trimMaxLength(data, maxLength) {
  if (data === null) return data;

  return data.substring(0, maxLength);
}

export function isImageFile(fileName) {
  const imageExtensions = ['JPG', 'JPEG', 'PNG', 'GIF'];
  const extension = fileName.split('.').pop();

  return imageExtensions.includes(extension.toUpperCase());
}