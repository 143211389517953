<template>
  <div class="flex search-container">
    <div class="search-field-container">
      <span class="fluid">
        <AutoComplete v-model="quickSearchItem" :suggestions="filteredItems" @complete="quickSearch($event)"
                      @item-select="selectItem" field="name" class="mr-2" @keypress.enter="search"/>
      </span>
    </div>
    <div>
        
    </div>
  </div>
</template>

<script>
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import { onMounted, ref, watch } from 'vue';
import * as ApiService from '@/api-service';
import { useRoute, useRouter } from 'vue-router';

export default {
  components: {
    AutoComplete,
    Button
  },

  setup() {
    const quickSearchItem = ref();
    const filteredItems = ref([]);

    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
      if (route.query.query) {
        quickSearchItem.value = route.query.query;
      }
     });

    watch(() => route.query, async () => {
      if (route.query.query) {
        quickSearchItem.value = route.query.query;
      } else {
        quickSearchItem.value = null;
      }
    });

    const quickSearch = async (event) => {
      const response = await ApiService.quickSearch(event.query);
      filteredItems.value = response.data;
    };

    const selectItem = (event) => {
      router.push({ name: 'Item', params: { id: event.value.id }});
    };

    const search = () => {
      router.push({ name: 'Items', query: { query: quickSearchItem.value }});
    };

    return {
      quickSearchItem,
      filteredItems,
      quickSearch,
      selectItem,
      search
    }
  }
}
</script>

<style scoped>
.search-container {
  background: var(--surface-50);
  border: 1px solid var(--surface-200);
  padding: 5px;
  border-radius: 3px;
}

.search-field-container {
  flex: 1;
}
</style>
