import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import * as ApiService from '@/api-service';
import userInfo from '@/store/user-info';
import { etLocale } from '@/i18n/et';
import './registerServiceWorker'

if (localStorage.getItem('access_token')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('access_token') }`;
}

(async () => {
  await loadCurrentUser();

  const app = createApp(App);
  app.use(router)
  app.use(VueAxios, axios)
  app.use(PrimeVue, { locale: etLocale })
  app.use(ToastService)
  app.use(ConfirmationService)
  app.mount('#app');
})();

async function loadCurrentUser() {
  let response = await ApiService.getCurrentUser();

  if (response.data.loggedIn === false && localStorage.getItem('access_token') !== null) {
    try {
      const refreshTokenResponse = await ApiService.refreshTokens();
      localStorage.setItem('access_token', refreshTokenResponse.data.access_token);
      localStorage.setItem('refresh_token', refreshTokenResponse.data.refresh_token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${ refreshTokenResponse.data.access_token }`;

      response = await ApiService.getCurrentUser();
    } catch (error) {
      await router.replace({ name: 'Login' });
    }
  }

  userInfo.setUserInfo(response.data);
}
